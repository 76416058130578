<template>
  <div class="app-container">
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <table style="background-color: bisque; width: 90%; margin: auto;" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000; text-align: center">
            <img width="100" src="https://soycain.com/img/logos/soycain-navbar.png" alt="Soycain Logo">
          </td>
          <td style="border: 1px solid #000; width: 40%; text-align: center">
            <p style="font-weight: bold; font-style: italic">Pôle Négoce/Section Filière</p>
            <p style="font-weight: bold; font-style: italic">Fiche d'enregistrement des parcelles</p>
          </td>
          <td style="border: 1px solid #000; text-align: center">
            <p style="font-weight: bold; font-style: italic">Version : 04</p>
            <p style="font-weight: bold; font-style: italic">Date : {{ date }}</p>
          </td>
        </tr>
      </table>
      <h4 style="width: 90%; margin-right: auto; margin-left: auto;">1.  IDENTIFICATION DU PRODUCTEUR</h4>
      <table v-if="data !== null" style="border : 1px solid #000000; width: 90%; margin: auto;" cellspacing="0">
        <!-- <tr>
          <th>Company</th>
          <th>Contact</th>
          <th>Country</th>
          <th>Country</th>
        </tr> -->
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
          <!-- <td style="border: 1px solid #000;">Code Parcelle/Date d’intégration Parcelle</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td> -->
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table>
      <h4 style="width: 90%; margin-right: auto; margin-left: auto;">2.  LISTE DES PARCELLES</h4>
      <table v-if="parcelles" style="border : 1px solid #000000; width: 90%; margin: auto;" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">
            Numéro
          </td>
          <td style="border: 1px solid #000;">
            Village
          </td>
          <td style="border: 1px solid #000;">
            Superficie (ha)
          </td>
          <!-- <td style="border: 1px solid #000;">
            Accessibilité en toute saison
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            Distance des habitations à la parcelle
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            Nombre d’arbres
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            Présence de cours d’eau (à moins de 10m)
          </td> -->
        </tr>
        <tr v-for="(parcelle, index) in parcelles" :key="parcelle.id">
          <td style="border: 1px solid #000;">
            {{ index + 1 }}
          </td>
          <td style="border: 1px solid #000;">
            {{ parcelle.village }}
          </td>
          <td style="border: 1px solid #000;">
            <a :href="getParcelleLink(parcelle.id)">{{ parcelle.superficie }}</a>
          </td>
          <!-- <td style="border: 1px solid #000; width:30%">
            OUI
            <input id="scales" type="checkbox" name="scales">
            NON
            <input id="scales" type="checkbox" name="scales">
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            {{ parcelle.dist_hab }}
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            {{ parcelle.nbr_arb }}
            Anacardier:...        Palmier:....
            Karité:.....      Autres:...
          </td> -->
          <!-- <td style="border: 1px solid #000;">
            OUI
            <input id="scales" type="checkbox" name="scales">
            NON
            <input id="scales" type="checkbox" name="scales">
          </td> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const producteursResource = new Resource('producteurs');
const groupementResource = new Resource('groupements');
const villageResource = new Resource('villages');

export default {
  name: 'ProducteurUnique',
  props: {
    produit: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: null,
      date: '',
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
    };
  },
  computed: {

  },
  created() {
    console.log('NOUS SOMEES KKKKKKKKKK');
    this.list = this.produit;
    this.getGroupementsList();
    this.getVillagesList();
    this.getProducteur();
  },
  mounted(){
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    showDetails(row) {
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    tableRowClassName({ row, rowIndex }) {
      return 'row-height';
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    goBack(){
      // this.$router.go(-1);
    },
    async getGroupementsList() {
      const { data } = await groupementResource.sublist('?typeApi="all"', this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    async getVillagesList() {
      const { data } = await villageResource.sublist('?typeApi="all"', this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getProducteur() {
      const { data } = await producteursResource.get(this.$route.params.id);
      this.parcelles = data.parcelles;
      this.data = data;
      console.log('leproduitttttttttt : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      // if (data !== null) {
      //   window.print();
      // }
    },
    printDiv(divName){
      // this.printing = true;
      // var printContents = document.getElementById(divName).innerHTML;
      // // var originalContents = document.body.innerHTML;
      // document.body.innerHTML = printContents;
      window.print();
      // document.body.innerHTML = originalContents;
      // this.printing = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.el-table .row-height {
  height: 30px;
  background: oldlace;
}
</style>
